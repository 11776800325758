/* eslint-disable eqeqeq */
import { Button } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { InternalFindAll } from '../../../redux/actions/InternalInvoiceAction';
import ClientPlusIcon from '../../Icons/ClientPlusIcon';
import SalesReturnClientModel from '../../models/SalesReturnClientModel';

function ClientDetailsReturn({
    setSelectedClientData, setNewexistProduct, paramsId, newExistProduct, setInvoiceDate, setProductRows, TaxAllList, setTaxAllList,
    handleChange, formData, setFormData, clientErrors, setReturnProducts, salesReturnDate, setSalesReturnDate


}: any) {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [existingData, setExistingData] = useState<any>(null);
    const [salesClients, setSalesClients] = useState<any[]>([]);


    const handleConfirm = () => {
        setIsModalOpen(true)
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const dispatch = useDispatch()

    const CustomInputWithIcon = ({ value, onClick }: any) => (
        <div className='relative'>
            <input
                type="text"
                value={value}
                readOnly
                className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                    borderColor: "#008065",
                }}
            />
            <FaCalendarAlt
                className="absolute right-8 top-0 mt-3 mr-2 cursor-pointer"
                onClick={onClick}
            />
        </div>
    );

    const handleSelect = (data: any) => {
        setReturnProducts()
        clientErrors.clientName = ""
        setFormData((formData: any) => ({
            ...formData,
            clientName: data.clientName,
            clientMobile: data.clientMobile,
            clientEmail: data.clientEmail,
            clientPan: data?.clientPan ? data?.clientPan : "",
            clientId: data._id?.$oid,
            invoiceNo: '',
            invoiceDate: data.invoicedate,
            _id: data.invoiceNo,
        }))
        setExistingData(data);
        setIsModalOpen(false);

    };

    

    const handleChanges = (e: any) => {
        const selectedInvoiceNo = e.target.value;

        setNewexistProduct([]);

        const selectedClient = salesClients.find(client => client.invoiceNo === selectedInvoiceNo);
        const nontaxableItems = selectedClient.product
        const nontaxableTotal = nontaxableItems.reduce((total: number, item: any) => {
            const rate = item.rate || 0;
            const qty = item.qty || 0;
            return total + rate * qty;
          }, 0);
        if (selectedClient) {
            setSelectedClientData((prevState: any) => ({
                ...prevState,
            }));

            const updatedProductRows = selectedClient.product.map((product: any) => (
                {
                prodcut: product.productName,
                hsn: product.hsn || "",
                rate: product.rate || "",
                qty: product.qty || "",
                amount: product.amount || "",
                igst: product.igst || "",
                cgst: product.cgst || "",
                sgst: product.sgst || "",
                discount: product.discount || ""
            }));

            const taxRows = selectedClient.tax.map((taxRow: any) => ({
                discountStatus: taxRow.discountStatus,
                discountPercentage: taxRow.discountStatus == true ? taxRow?.taxPersent : 0,
                cgstStatus: taxRow.cgstStatus,
                cgstPercentage: taxRow.cgstStatus == true ? taxRow?.taxPersent : 0,
                sgstStatus: taxRow.sgstStatus,
                sgstPercentage: taxRow.sgstStatus == true ? taxRow?.taxPersent : 0,
                igstStatus: taxRow.igstStatus,
                igstPercentage: taxRow.igstStatus == true ? taxRow?.taxPersent : 0,
                othersStatus: taxRow.othersStatus,
                othersPercentage: taxRow.othersStatus == true ? taxRow?.taxPersent : 0,
                extraFeesStatus: taxRow.extraFeesStatus,
                extraFees: taxRow.extraFeesStatus == true ? taxRow?.taxPersent : 0,
            }));

            // Filter out any empty rows before adding the new data
            const containsEmptyRowsinExistProduct = newExistProduct.some((row: any) =>
                Object.values(row).some((value) => !value)
            );

            if (containsEmptyRowsinExistProduct) {
                const nonEmptyRows = newExistProduct.filter((row: any) =>
                    Object.values(row).some(Boolean)
                );
                setNewexistProduct(nonEmptyRows);
            }

            // Add the updated product rows to newExistProduct
            setNewexistProduct((prev: any) => [
                ...prev,
                ...updatedProductRows.map((row: any) => ({
                    exist: Number(row.qty),
                    originalValue: Number(row.qty)
                }))
            ]);

            setProductRows(updatedProductRows);
            setTaxAllList(taxRows);
            setReturnProducts(selectedClient.product);
            setFormData((formData: any) => ({
                ...formData,
                billTaxType: selectedClient.billTaxType,
                gstType: selectedClient.gstType,
                tax: selectedClient.tax,
                subtotal: selectedClient.subtotal,
                invoiceNo: selectedInvoiceNo,
                invoiceDate: selectedClient.invoicedate,
                product: selectedClient.product,
                toatalAmount: selectedClient.toatalAmount,
                clientId: selectedClient.clientId,
                invoiceTemplateType:selectedClient.invoiceTemplateType,
                colorTemplateType: selectedClient.colorTemplateType,
                nontaxableTotal: nontaxableTotal,
            }));
        }

        clientErrors.invoiceNo = "";
    };


    const fetchData = () => {
        dispatch(InternalFindAll() as any).then((response: any) => {
            if (response && response.payload) {
                // closeModal();
                setIsModalOpen(false);
                setSalesClients(response.payload)
            }
        });
    }



    const invoiceNo = salesClients.filter(client => client.clientId === existingData?.clientId).map(client => client.invoiceNo);

    useEffect(() => {
        fetchData()
    }, [])


    return (
        <div className={"flex flex-col sm:flex-row pt-3 pr-8"}>
            <div className="  flex-1 md:mx-2 mt-2 md:mt-[2px] ">
                <div className="flex flex-col md:flex-row justify-between items-left">
                    <div
                        className="flex flex-1 items-center whitespace-nowrap justify-start"
                        style={{
                            color: "#008065",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                    >
                        Client Details (Billed To)
                    </div>

                    <div className="flex flex-1 items-center justify-center md:justify-end mr-2">
                        <ClientPlusIcon className="w-4 h-4" />
                        <Button onClick={handleConfirm}>
                            <span
                                style={{
                                    color: "#008065",
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                }}
                                className="text-[#008065] ml-1"
                            >
                                EXISTING
                            </span>
                        </Button>
                    </div>


                    {isModalOpen && (
                        <SalesReturnClientModel closeModal={closeModal} handleSelect={handleSelect} />
                    )}
                </div>



                <div className="flex flex-row justify-between items-center mb-2">
                    <div
                        className="flex-1  required"
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                    >
                        <span>Name</span>
                    </div>
                    <div className="font-title flex-1 text-left">

                        <input
                            autoComplete="nope"
                            placeholder="Client Name"
                            className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                borderColor: clientErrors.clientName ? "red" : "#008065",
                            }}
                            name='clientName'
                            value={formData.clientName}
                            onChange={(e) => handleChange(e)}
                            readOnly
                        />
                        {clientErrors.clientName && (
                            <p
                                style={{
                                    fontFamily: "poppins",
                                    fontWeight: 700,
                                    color: "#ff0000",
                                }}
                                className="text-red-400 text-xs"
                            >
                                {clientErrors.clientName}
                            </p>
                        )}

                    </div>
                </div>

                <div className="flex flex-row justify-between items-center mb-2">
                    <div
                        className=" flex-1 mb-2"
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                    >
                        {" "}
                        Phone{" "}
                    </div>
                    <div className="font-title flex-1 text-left">
                        <input
                            type="text"
                            autoComplete="nope"
                            placeholder="Mobile Number"
                            className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                borderColor: "#008065",
                            }}
                            maxLength={10}
                            name="clientMobile"
                            value={formData.clientMobile}
                            readOnly
                            onChange={(e) => {
                                const re = /^[0-9\b]+$/;
                                if (re.test(e.target.value) || (e.target.value == "")) {
                                    handleChange(e)
                                }

                            }}

                        />

                    </div>
                </div>

                <div className="flex flex-row justify-between items-center mb-2">
                    <div
                        className="flex-1"
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                    >
                        Email
                    </div>
                    <div className="font-title flex-1 text-left ">

                        <input
                            autoComplete="nope"
                            placeholder="Email (Optional)"
                            className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                borderColor: "#008065",
                            }}
                            name='clientEmail'
                            readOnly
                            value={formData.clientEmail}
                            onChange={(e) => handleChange(e)}
                        />


                    </div>
                </div>

                <div className="flex flex-row justify-between items-center mb-2">
                    <div
                        className="flex-1"
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                    >
                        PAN
                    </div>
                    <div className="font-title flex-1 text-left ">

                        <input
                            autoComplete="nope"
                            placeholder="PanNumber (Optional)"
                            className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                borderColor: "#008065",
                            }}
                            name='clientPan'
                            readOnly
                            value={formData.clientPan}
                            onChange={(e) => handleChange(e)}
                        />


                    </div>
                </div>


            </div >
            <div className="  flex-1 md:mx-2">
                <div>
                    <div className="sm:flex-1 mt-7 p-2">
                        <div className="flex flex-row justify-between items-center mb-2">
                            <div
                                className="flex-1  required"
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                }}
                            >
                                <span>Invoice No</span>
                            </div>
                            <div className="font-title flex-1 text-left">

                                <select
                                    autoComplete="nope"
                                    className={"border-2 focus:outline-none rounded-[7px] px-2 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12"}
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        borderColor: clientErrors.invoiceNo ? 'red' : "#008065",
                                    }}
                                    name="invoiceNo"
                                    value={formData.invoiceNo}
                                    onChange={(e) => handleChanges(e)}
                                >
                                    <option value="" selected hidden>{paramsId ? formData.invoiceNo : "Select an Invoice No"}</option>
                                    {invoiceNo.map((invoiceNo, index) => (
                                        <option key={index} value={invoiceNo}>
                                            {invoiceNo}
                                        </option>
                                    ))}
                                </select>

                                {clientErrors.invoiceNo && (
                                    <p
                                        style={{
                                            fontFamily: "poppins",
                                            fontWeight: 700,
                                            color: "#ff0000",
                                        }}
                                        className="text-red-400 text-xs"
                                    >
                                        {clientErrors.invoiceNo}
                                    </p>
                                )}

                            </div>
                        </div>

                        <div className="flex flex-row justify-between items-center mb-2">
                            <div
                                className=" flex-1 mb-2"
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                }}
                            >
                                {" "}
                                Sales Return No{" "}
                            </div>
                            <div className="font-title flex-1 text-left">
                                <input
                                    type="text"
                                    autoComplete="nope"
                                    placeholder="Mobile Number"
                                    className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        borderColor: "#008065",
                                    }}
                                    maxLength={10}
                                    name="SalesreturnNo"
                                    value={formData.SalesreturnNo}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/;
                                        if (re.test(e.target.value) || (e.target.value == "")) {
                                            handleChange(e)
                                        }

                                    }}

                                />

                            </div>
                        </div>

                        <div className="flex flex-row justify-between items-center mb-2">
                            <div
                                className="flex-1"
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                }}
                            >
                                Invoice Date
                            </div>
                            <div className="font-title flex-1 text-left ">

                                <input
                                    autoComplete="nope"
                                    placeholder="Invoice Date"
                                    className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        borderColor: "#008065",
                                    }}
                                    name='invoiceDate'
                                    value={formData.invoiceDate}
                                    readOnly
                                />



                            </div>
                        </div>

                        <div className="flex flex-row justify-between items-center mb-2">
                            <div
                                className="flex-1"
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                }}
                            >
                                Sales Return Date
                            </div>
                            <div className=" font-title flex-1 text-left">
                                <DatePicker
                                    selected={salesReturnDate}
                                    value={formData?.salesReturnDate}
                                    onChange={(date) => {
                                        setSalesReturnDate(date);
                                        setFormData({
                                            ...formData,
                                            salesReturnDate: moment(date).format("DD/MM/YYYY"),
                                        });
                                    }}
                                    dateFormat={"dd/MM/yyyy"}
                                    wrapperClassName="return-datepicker"
                                    scrollableYearDropdown
                                    customInput={<CustomInputWithIcon />}
                                    showPopperArrow={false}

                                />
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div >
    )

}

export default ClientDetailsReturn